import client from "./client";

const endpoint = "/v1/PkgsBulks";

const packages = (shipmentType, number, code, page, docPerPage) =>
  client.get(
    `${endpoint}?cargoType=${shipmentType}&number=${number}&CountryCode=${code}&Page=${page}&PostsPerPage=${docPerPage}`
  );

const updatePackage = (shipmentType, id, schema) =>
  client.put(`${endpoint}/${id}?cargoType=${shipmentType}`, schema);

const printCustomer = (shipmentType, id, customerCode) =>
  client.get(`${endpoint}/print${shipmentType}?id=${id}&code=${customerCode}`);

const paid = (shipmentType, id) =>
  client.put(`${endpoint}/paid/${id}?cargoType=${shipmentType}`);

const searchByCode = (shipmentType, id, code, pageNo = 1, docsPerPage = 5) =>
  client.put(
    `${endpoint}/SearcByCode${shipmentType}/${id}?CustomerCode=${code}&Page=${pageNo}&PostsPerPage=${docsPerPage}`
  );

const createCustomerPaymentFromCargo = (schema) =>
  client.post(`${endpoint}/CreateCustomerPaymentFromCargo`, schema);

export default {
  packages,
  updatePackage,
  printCustomer,
  paid,
  searchByCode,
  createCustomerPaymentFromCargo,
};

import client from "./client";

const endpoint = "/v1/Customer";

const customers = (page, docPerPage) =>
  client.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}`);

const preload = (page, docPerPage) =>
  client.get(`${endpoint}/preload?Page=${page}&PostsPerPage=${docPerPage}`);

const findByName = (name, page, docPerPage) =>
  client.get(
    `${endpoint}/FindByName?filter=${name}&Page=${page}&PostsPerPage=${docPerPage}`
  );

const createCustomer = (schema) => client.post(`${endpoint}/`, schema);

const getAllCustomers = (page, docPerPage) =>
  client.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}`);

const deleteCustomer = (id) => client.delete(`${endpoint}/${id}`);

const updateCustomer = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const getByColumn = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const advanceSearch = (schema, page, docsPerPage, shipment) =>
  client.put(
    `${endpoint}/advanceFilter?Page=${page}&PostsPerPage=${docsPerPage}`,
    schema
  );

// This api for filter and has no permission
const getByPopupCoulum = (col, val, page, docsPerPage) =>
  client.put(
    `${endpoint}/GetByPopupCoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const customersNotHaveAccount = (customerCode, page, docPerPage) =>
  client.get(
    `${endpoint}/customerNotHaveAccount?customerCode=${customerCode}&Page=${page}&PostsPerPage=${docPerPage}`
  );

const linkedCustomers = (page, docsPerPage) =>
  client.get(
    `${endpoint}/LinkedCustomers?Page=${page}&PostsPerPage=${docsPerPage}`
  );

const linkedCustomersByCoulum = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/LinkedCustomersByCoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

export default {
  preload,
  customers,
  createCustomer,
  findByName,
  getAllCustomers,
  deleteCustomer,
  updateCustomer,
  getByColumn,
  getByPopupCoulum,
  customersNotHaveAccount,
  linkedCustomers,
  linkedCustomersByCoulum,
  advanceSearch,
};

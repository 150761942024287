import jwt_decode from "jwt-decode";

const useAbility = (pageName = "") => {
  const userPermission =
    localStorage.getItem(btoa("token")) !== null
      ? jwt_decode(localStorage.getItem(btoa("token")))
      : {};

  const checkAbility = (permission, page) => {
    return userPermission[page || pageName]?.includes(permission);
  };

  return checkAbility;
};

export default useAbility;

export default {
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذا الزبون ؟",
    en: "Are you sure you want to delete this customer ?"
  },
  page: {
    ar: "اعدادات العملاء",
    en: "Customers settings"
  },
  activateCustomer: {
    ar: "هل انت متأكدمن انك تريد تفعيل هذا الزبون ؟",
    en: "Are you sure you want to activate this Customer ?"
  },
  deactivateCustomer: {
    ar: "هل انت متأكد من انك تريد الغاء تفيل هذا الزبون ؟",
    en: "Are you sure you want to deactivate this Customer ?"
  }
}
import { useState, useEffect } from "react";
import { CardBody, Row, FormGroup, Input, Col, Button } from "reactstrap";
import { useRouteMatch, useLocation } from "react-router-dom";
import getCulture from "utils/getCulture";
import commenContent from "components/common/content";
import packagesInBulk from "api/packagesInBulk";
import useApi from "hooks/useApi";

const Filter = ({ bulkId, code, setCode, filterFun }) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const location = useLocation();

  const searchByCode = useApi(packagesInBulk.searchByCode);

  useEffect(() => {
    setCode("");
  }, [location]);

  return (
    <CardBody>
      {searchByCode.errorAlert}
      <Row>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="search">
              {commenContent.customerCode[culture]}
            </label>
            <Input
              id="search"
              type="text"
              value={code || ""}
              placeholder={commenContent.customerCode[culture]}
              onChange={({ target: { value } }) => setCode(value)}
              onKeyPress={(e) => e.key === "Enter" && filterFun()}
            />
          </FormGroup>
        </Col>
        <Col className="align-self-center">
          <Button
            color="success"
            className="mt-1"
            type="button"
            onClick={filterFun}
            disabled={!bulkId}
          >
            <span className="btn-inner--icon me-1">
              <i className="fas fa-search" />
            </span>
            <span>{commenContent.search[culture]}</span>
          </Button>
        </Col>
      </Row>
    </CardBody>
  );
};

export default Filter;

import React, { useState } from "react";
import home from "api/home";
import useCulture from "hooks/useCulture";
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import useApi from "hooks/useApi";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

const content = {
  filterBizznassOrderNumber: {
    ar: "بحث برقم طلب بزناس",
    en: "Filter bizznass order number",
  },
  noOrderFound: {
    ar: "لم يتم العثور على اي شحنه بهذا الرقم",
    en: "No cargo was found with this number",
  },
};

function FilterBizznassOrderNumber() {
  const history = useHistory();
  const culture = useCulture();

  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");

  const searchOrderApi = useApi(home.buzznasOrder);

  const handleSearch = async (e) => {
    e.preventDefault();
    setMessage("");
    const res = await searchOrderApi.request(+search);
    if (res.status === 200) {
      console.log({ res });
      if (!res.data.data.path) {
        setMessage(content.noOrderFound[culture], true);
      } else {
        history.push(
          `/${culture}-admin/${res.data.data.path}/${res.data.data.id}`
        );
      }
    }
  };
  return (
    <Form
      onSubmit={handleSearch}
      className={classnames(
        "navbar-search form-inline mr-sm-3 navbar-search-light"
      )}
    >
      <FormGroup className="mb-0 d-flex flex-column">
        <InputGroup className="input-group-alternative input-group-merge">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <Button
                type="submit"
                style={{ all: "unset", "&:hover": "unset" }}
                disabled={!search || searchOrderApi.loading}
              >
                <i className="fas fa-search cursor-pointer" />
              </Button>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder={content.filterBizznassOrderNumber[culture]}
            type="number"
            pattern="/[0-9]+/"
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              setMessage("");
              setSearch(e.target.value);
            }}
          />
        </InputGroup>
        <span>{message}</span>
      </FormGroup>
    </Form>
  );
}

export default FilterBizznassOrderNumber;

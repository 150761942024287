export default {
  orderId: {
    ar: "معرٌف الطلب",
    en: "Order Id",
  },
  orderBulk: {
    ar: "تجميعة الطلب",
    en: "Order Bulk",
  },
  customerCode: {
    ar: "كود الزبون",
    en: "Customer Code",
  },
  customerName: {
    ar: "اسم الزبون",
    en: "Customer Name",
  },
  weight: {
    ar: "الوزن",
    en: "Weigth",
  },
  noOfPackages: {
    ar: "عدد البضائع المحملة",
    en: "NO Of Packages",
  },
  numberOfPackages: {
    ar: "عدد البضائع المحملة",
    en: "Number Of Packages",
  },
  followUpStatus: {
    ar: "متابعة الحالات",
    en: "Follow Up Status",
  },
  receiveDate: {
    ar: "تاريخ الاستخدام",
    en: "Recieve Date",
  },
  stateDate: {
    ar: "تاريخ الحالة",
    en: "State Date",
  },
  airShipment: {
    ar: "الشحن الجوي",
    en: "AirShipment",
  },
  weigthOrVolume: {
    ar: "الوزن او الحجم",
    en: "WeigthOrVolume",
  },
  bulk: {
    ar: "التجميعة",
    en: "Bulk",
  },
  trackNumber: {
    ar: "رقم الشاحنة",
    en: "Track Number",
  },
  trackLine: {
    ar: "مسار الشاحنة",
    en: "Track Line",
  },
  air: {
    ar: "الجوي",
    en: "Air",
  },
  remainigNoOfPackage: {
    ar: "العدد المتبقي من الصناديق",
    en: "Remaining number of boxes",
  },
  remainingWeight: {
    ar: "الوزن المتبقي",
    en: "Remaining weight",
  },
  exportDate: {
    ar: "تاريخ التصدير",
    en: "Export Date",
  },
  changeToWitingChange: {
    ar: "تغيير الشحنه الى انتظار التغيير",
    en: "Change To Waiting Change",
  },
  changeShipmentToWitingChange: {
    ar: "أنت علي وشك تغيير حالة الشحنه الى حالة في انتظار التغيير ؟",
    en: "You're about to change this shipment state to waiting change state ?",
  },
};

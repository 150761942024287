import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Container,
  UncontrolledTooltip,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import Pagination from "components/common/Pagination";
import useSearchPopup from "hooks/useSearchPopup";
import env from "env";
import shipmentApi from "api/shipment";
import lclApi from "api/LCLBK";
import followUpStatus from "api/followUpStatus";
import useApi from "hooks/useApi";
import useAlert from "hooks/useAlert";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import SearchModal from "components/common/SearchModal";
import Spinner from "../../components/Spinner";
import { PaginationContext } from "context/PaginationContext";
import usePagination from "hooks/usePagination";
import EditAirshipment from "Screen/Airshipment/components/EditAirshipment";
import TextPopup from "components/common/TextModal";
import EditLCL from "Screen/SeaShipments/LCL/components/EditLCL";
import content from "../Airshipment/content";
import commenContent from "components/common/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";
import usePrevRequest from "hooks/usePrevRequest";
import useAdvanceSearch from "hooks/useAdvanceSearch";
import useCheckRequest from "hooks/useCheckRequest";
import AdvanceTable from "components/common/AdvanceTable";
import AdvanceSearchModal from "components/common/AdvanceSearchModal";

const FollowUpStatus = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  // useParams
  const params = useParams();
  const location = useLocation();

  const shipmentType = params.shipmentType.toLowerCase();

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    content.orderId[culture],
    content.followUpStatus[culture],
    content.orderBulk[culture],
    content.customerCode[culture],
    content.customerName[culture],
    shipmentType === "aircargo"
      ? commenContent.weight[culture]
      : commenContent.volume[culture],
    content.noOfPackages[culture],
    commenContent.branch[culture],
    commenContent.country[culture],
    commenContent.date[culture],
    content.receiveDate[culture],
    content.stateDate[culture],
    content.exportDate[culture],
    commenContent.lastModifiedOn[culture],
    commenContent.commodity[culture],
    commenContent.commodityType[culture],
  ]);

  const [columns, setColumns] = useState([
    "orderNumber",
    "followUpStatusText",
    "orderBulk",
    "customer.customerCode",
    "customer.customerName",
    shipmentType === "aircargo" ? "weight" : "volume",
    "numberOfPkgs",
    "branch.name",
    "country.name",
    "createdOn",
    "recieveDate",
    "statusDate",
    "exportDate",
    "lastModifiedOn",
    "commodity",
    "commodityTypeText",
  ]);

  const uploadImg = useApi(
    shipmentType === "aircargo" ? shipmentApi.uploadImg : lclApi.uploadImgLCL
  );
  const updateCargo = useApi(
    shipmentType === "aircargo" ? shipmentApi.updateCargo : lclApi.updateLCL
  );

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();
  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = `${params.shipmentType}-followUpStatus`;

  const paginationCtx = useContext(PaginationContext);

  // Apis
  const getFUS = useApi(followUpStatus.getFUS);
  const getByColumn = useApi(followUpStatus.advanceSearch);

  // sttates
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editImage, setEditImage] = useState({});

  const {
    handleSubmit: editHandleSubmit,
    formState: editFormState,
    control: editControl,
    setValue: editSetValue,
  } = useForm();

  const editResponse = (res) => {
    res.createdOn = res.createdOn.split("T")[0];
    res.recieveDate = res.recieveDate?.split("T")[0];
    res.statusDate = res.statusDate?.split("T")[0];
    res.exportDate = res.exportDate?.split("T")[0];
    res.lastModifiedOn = res.lastModifiedOn?.split("T")[0];

    // Replace the normal status with the text from env variable
    const thisStatusIndex = env.followUpStatus.findIndex(
      (status) => status.key === res.followUpStatus
    );

    res.followUpStatusText = env.followUpStatus[thisStatusIndex]?.[culture];

    res.commodityTypeText = env.commodityType?.[res.commodityType - 1];

    const acOrLc =
      params.shipmentType.toLowerCase() === "aircargo" ? "AC" : "LC";
    res.orderNumber = `${res.country?.countryCode}-${res.orderNumber}${acOrLc}`;
    // lclBulkCargos airBulks
    const bulkField =
      shipmentType.toLowerCase() === "aircargo" ? "airBulks" : "lclBulks";

    const bulkPage =
      shipmentType.toLowerCase() === "aircargo" ? "aircargo" : "lcl";

    res.orderBulk = (
      <span>
        {res[bulkField]?.map((item) => (
          <Link
            to={`/en-admin/bulk/${bulkPage}/${item.id}`}
            className="me-1"
            key={item.id}
          >
            B-{item.bulkPckgNum}
            {item.country?.countryCode}
          </Link>
        ))}
      </span>
    );

    return res;
  };

  const {
    key,
    setKey,
    openPopup,
    setOpenPopup,
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    register,
    onHeaderClick,
    searchHandler,
    setSearchField,
    header,
    isRemove,
    setIsRemove,
    handleRemove,
    checkPrev,
    resetAdvanceFilter,
  } = useAdvanceSearch(pageName, editResponse);

  const getData = async () => {
    const res = await getFUS.request(params.shipmentType, pageNo, docsPerPage);

    if (res.status === 200) {
      res.data.data = res.data.data.map((item) => editResponse(item));
      setData(res.data.data);
    }
  };

  const updateData = (arr) => {
    // const editedResponse = arr.map((response) => editResponse(response));

    setData(arr);
  };

  // const handleSearch = async () => {
  //   // if search filed is empty get the normal request
  //   // if (!search) return getData();

  //   // paginationCtx.setSearch(search);
  //   // set the 'searchPopup' state to false to uses the search field data
  //   // searchPopup.setIsSelected(false);

  //   const res = await getByColumn.request(
  //     "followUpStatus",
  //     search,
  //     pageNo,
  //     docsPerPage,
  //     params.shipmentType
  //   );

  //   if (res.status === 200) {
  //     const editedResponse = res.data.data.map((response) =>
  //       editResponse(response)
  //     );
  //     setData(editedResponse);
  //   }
  //};

  const handleUpload = async (id) => {
    const res = await uploadImg.request(id, editImage);
    return res?.ok || false;
  };

  const editAirCargo = async (formData) => {
    setSpinner(true);
    setEditModal(false);

    const rowId = { ...formData }.id;
    delete formData.id;
    delete formData.country;

    // Assing 'uploadSucceed' varibale with true default value
    let uploadSucceed = true;

    // if the 'editImage' has 'imgs' attribute and the 'imgs' attribute has a value
    // 1) upload the imgs
    // 2) override the 'uploadSucceed' varibale
    if (Object.keys(editImage).length)
      uploadSucceed = await handleUpload(rowId);

    // if the upload fails don't continue to the next request
    if (!uploadSucceed) return setSpinner(false);
    // if no other filed edited don't continue to the next request
    if (!editFormState.isDirty) {
      // and show success for the upload field
      sweetAlert(commenContent.done[culture]);
      return setSpinner(false);
    }

    const res = await updateCargo.request(rowId, formData);

    if (res.status === 200) {
      const newData = [...data];
      const editedIndex = newData.findIndex((item) => item.id === rowId);

      res.data.data = editResponse(res.data.data);
      newData[editedIndex] = res.data.data;

      setData(newData);
      sweetAlert(commenContent.done[culture]);
      setEditImage({});
    }

    setSpinner(false);
  };

  const handleEdit = (obj) => {
    const weightOrVolume = shipmentType === "aircargo" ? "weight" : "volume";

    setEditImage({});
    editSetValue("id", obj.id);
    editSetValue(weightOrVolume, obj[weightOrVolume]);
    editSetValue("numberOfPkgs", obj.numberOfPkgs);
    editSetValue("country", obj.country.name);
    editSetValue("commodityType", obj.commodityType);
    editSetValue("commodity", obj.commodity);
    editSetValue("countryId", obj.country.id);
    editSetValue("client", obj.customer?.customerName);
    editSetValue("customerId", obj.customer?.id);
    setEditModal(true);
  };

  const closeAdvanceSearch = (index) => {
    handleRemove(index, false);
    setOpenPopup(false);
  };

  const handleSearch = async () => {
    const res = await searchHandler(
      getByColumn,
      pageNo,
      docsPerPage,
      shipmentType
    );
    if (res.ok) {
      updateData(res.data.data);
      register({ key: 1, pageNo, docsPerPage, pageName });
    }
  };
  console.log({ location });
  useEffect(() => {
    if (!checkPrev()) getData();
  }, [location]);

  useCheckRequest({
    pageNo,
    isRemove,
    setIsRemove,
    requests: [getData, handleSearch],
    setPageNo,
    setDocsPerPage,
    docsPerPage,
    key,
    register,
    depandancies: [location],
    resetAdvanceFilter,
  });

  return (
    <div>
      {alert}
      {getFUS.errorAlert}
      {uploadImg.errorAlert}
      {updateCargo.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getFUS.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader
            parents={[
              `${
                shipmentType === "aircargo"
                  ? content.followUpStatus[culture] + " " + content.air[culture]
                  : "Lcl " + content.followUpStatus[culture]
              } `,
            ]}
          />
          <Container className="mt--6" fluid>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <h3 className={`mb-0 text-md-left`}>
                    {shipmentType === "aircargo"
                      ? content.followUpStatus[culture] +
                        " " +
                        content.air[culture]
                      : "Lcl " + content.followUpStatus[culture]}
                  </h3>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="follow-up-status"
                      >
                        {content.followUpStatus[culture]}
                      </label>
                      <Input
                        id="follow-up-status"
                        type="select"
                        // value={search || "disabled"}
                        // onChange={(e) => setSearch(e.target.value)}
                      >
                        <option disabled value="disabled">
                          {`-- ${commenContent.selectOption[culture]} --`}
                        </option>
                        {env.followUpStatus.map((status) => (
                          <option value={status.key} key={status.key}>
                            {status?.[culture]}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  {/* <Col className="mt-2">
                    <Button
                      color="success"
                      type="button"
                      className="mt-4"
                      onClick={handleSearch}
                    >
                      <span className="btn-inner--icon me-1">
                        <i className="fas fa-search" />
                      </span>
                      <span>{commenContent.search[culture]}</span>
                    </Button>
                  </Col> */}
                </Row>
              </CardBody>
              <Pagination
                dataLength={data.length}
                pageNo={pageNo}
                setPageNo={setPageNo}
                docsPerPage={docsPerPage}
                setDocsPerPage={setDocsPerPage}
              >
                <AdvanceTable
                  pageName={pageName}
                  headers={headers}
                  columns={columns}
                  setHeaders={setHeaders}
                  setColumns={setColumns}
                  handleEdit={handleEdit}
                  // when the user search using the popup and select a row
                  // after then only use the hookData states from searchPopup hook
                  data={data}
                  setData={setData}
                  searchedHeaders={header}
                  onHeaderClick={onHeaderClick}
                  handleRemove={handleRemove}
                >
                  <span
                    to={`/en-admin/${
                      params.shipmentType.toLowerCase() === "aircargo"
                        ? "airshipment"
                        : "lcl"
                    }`}
                  >
                    <div id="details1" className="table-action cursor-pointer">
                      <i className="far fa-eye fa-lg hover-success"></i>
                    </div>
                    <UncontrolledTooltip delay={0} target="details1">
                      {commenContent.showDetails[culture]}
                    </UncontrolledTooltip>
                  </span>
                  <span
                    fun="handleEdit"
                    condition="renderEdit"
                    className="me-1"
                  >
                    <div id="edit1" className="table-action cursor-pointer">
                      <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                    </div>
                    <UncontrolledTooltip delay={0} target="edit1">
                      {commenContent.edit[culture]}
                    </UncontrolledTooltip>
                  </span>
                </AdvanceTable>
              </Pagination>
            </Card>
          </Container>
        </>
      )}
      {shipmentType.toLowerCase() === "aircargo" && (
        <TextPopup
          modal={editModal}
          text="Update"
          handleCancel={() => setEditModal(false)}
          fn={editHandleSubmit(editAirCargo)}
          color="success"
          disabled={!editFormState.isDirty && !Object.keys(editImage).length}
        >
          <Container>
            <EditAirshipment
              control={editControl}
              errors={editFormState.errors}
              setValue={editSetValue}
              editImage={editImage}
              setEditImage={setEditImage}
            />
          </Container>
        </TextPopup>
      )}
      {shipmentType.toLowerCase() === "lcl" && (
        <TextPopup
          modal={editModal}
          text="Update"
          handleCancel={() => setEditModal(false)}
          fn={editHandleSubmit(editAirCargo)}
          color="success"
          disabled={!editFormState.isDirty && !Object.keys(editImage).length}
        >
          <Container>
            <EditLCL
              control={editControl}
              errors={editFormState.errors}
              setValue={editSetValue}
              editImage={editImage}
              setEditImage={setEditImage}
            />
          </Container>
        </TextPopup>
      )}
      {openPopup && (
        <AdvanceSearchModal
          setKey={setKey}
          openPopup={openPopup}
          pageName={pageName}
          getByColumn={getByColumn}
          updateData={updateData}
          setParentPageNo={setPageNo}
          setParentDocsPerPage={setDocsPerPage}
          setParentSearchField={setSearchField}
          handleClose={closeAdvanceSearch}
          editResponse={editResponse}
          extraParams={[shipmentType]}
          extraField={{
            header: content.orderId[culture],
            column: "orderNumber",
          }}
        />
      )}
    </div>
  );
};

export default FollowUpStatus;

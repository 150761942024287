import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  UncontrolledTooltip,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useRouteMatch } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader";
import useApi from "hooks/useApi";
import Spinner from "../../../components/Spinner";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import usePagination from "hooks/usePagination";
import Pagination from "components/common/Pagination";
import Table from "components/common/CustomTable";
import useSearchPopup from "hooks/useSearchPopup";
import SearchModal from "components/common/SearchModal";
import EditCustomer from "./components/EditCustomer";
import customer from "api/customer";
import useAbility from "hooks/useAbility";
import getCulture from "utils/getCulture";
import commonContent from "components/common/content";
import env from "env";
import content from "./content";
import FilePicker from "components/common/FilePicker";
import useFormData from "hooks/useFormData";
import AdvanceTable from "components/common/AdvanceTable";
import useAdvanceSearch from "hooks/useAdvanceSearch";
import useCheckRequest from "hooks/useCheckRequest";
import AdvanceSearchModal from "components/common/AdvanceSearchModal";
import commenContent from "components/common/content";
import user from "api/user";

const CustomerSettings = () => {
  const rout = useRouteMatch();
  const history = useHistory();
  const culture = getCulture(rout.url);
  const [headers, setHeaders] = useState([
    commonContent.actions[culture],
    commonContent.name[culture],
    commonContent.code[culture],
    commonContent.phone[culture],
    commonContent.branch[culture],
    commonContent.date[culture],
  ]);
  const [columns, setColumns] = useState([
    "customerName",
    "customerCode",
    "phone",
    "currentBranch.name",
    "createdOn",
  ]);

  const pageName = "customes-settings";

  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [rowId, setRowId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);

  const checkAbility = useAbility("Customer");
  const { alert, sweetAlert } = useAlert();

  const formDataGenerator = useFormData();

  // use form hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm();

  const {
    handleSubmit: editHandleSubmit,
    formState: { errors: editErrors },
    control: editControl,
    setValue: editSetValue,
  } = useForm();

  // const { pageNo, setPageNo, docsPerPage, setDocsPerPage } = usePagination("");

  const customersApi = useApi(customer.preload);
  const createCustomerApi = useApi(customer.createCustomer);
  // const getByColumn = useApi(customer.getByColumn);
  const getByColumn = useApi(customer.advanceSearch);

  const deleteCustomerApi = useApi(customer.deleteCustomer);
  const updateCustomerApi = useApi(customer.updateCustomer);
  const activateUserApi = useApi(user.userActive);
  const deactivateUserApi = useApi(user.userDeactive);

  const editResponse = (item) => {
    item.createdOn = item.createdOn.split("T")[0];
    return item;
  };

  // const searchPopup = useSearchPopup(
  //   getByColumn,
  //   headers,
  //   columns,
  //   data,
  //   editResponse
  // );

  const {
    key,
    setKey,
    openPopup,
    setOpenPopup,
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    register,
    onHeaderClick,
    searchHandler,
    setSearchField,
    header,
    isRemove,
    setIsRemove,
    handleRemove,
    resetAdvanceFilter,
  } = useAdvanceSearch(pageName, editResponse);

  const closeAdvanceSearch = (index) => {
    handleRemove(index, false);
    setOpenPopup(false);
  };

  const createCustomer = async (formData) => {
    setSpinner(true);
    const schema = formDataGenerator(formData);

    const res = await createCustomerApi.request(schema);
    if (res.status === 200) {
      setData((d) => ({ ...d, data: [res.data.data, ...d.data] }));
      sweetAlert("Done");
      reset();
    }
    setSpinner(false);
  };

  const getCustomers = async () => {
    const res = await customersApi.request(pageNo, docsPerPage);

    if (res.status === 200) {
      res.data.data.data.forEach(editResponse);
      setData(res.data.data);
    }
  };

  const activateUserHandler = async () => {
    setActivateModal(false);
    setSpinner(true);
    const res = await activateUserApi.request(rowId);
    if (res.status === 200) {
      await getCustomers();
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
    setRowId("");
  };

  const deactivateUserHandler = async () => {
    setSpinner(true);
    setDeactivateModal(false);
    const res = await deactivateUserApi.request(rowId);
    if (res.status === 200) {
      await getCustomers();
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
    setRowId("");
  };

  const updateData = (arr) => {
    setData((d) => ({ ...d, data: arr }));
  };

  const handleCancel = () => {
    setRowId("");
    setEditModal(false);
    setDeleteModal(false);
    setActivateModal(false);
    setDeactivateModal(false);
  };

  const handleActivate = (obj) => {
    setRowId(obj.applicationUserId);
    setActivateModal(true);
  };

  const handleDeactivate = (obj) => {
    setRowId(obj.applicationUserId);
    setDeactivateModal(true);
  };

  const deleteRow = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteCustomerApi.request(rowId);
    if (res.status === 200) {
      const newData = data.data.filter((item) => item.id !== rowId);
      setData((d) => ({ ...d, data: newData }));
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  const handleEdit = (obj) => {
    setRowId(obj.id);
    editSetValue("customerName", obj.customerName);
    editSetValue("phone", obj.phone);
    editSetValue("priceType", obj.priceType);
    editSetValue("currentBranchId", obj.currentBranch?.id || "");
    setEditModal(true);
  };

  const editCustomerHandler = async (formData) => {
    setEditModal(false);
    setSpinner(true);
    const res = await updateCustomerApi.request(rowId, formData);

    if (res.status === 200) {
      const newData = [...data.data];
      const rowIndex = newData.findIndex((item) => item.id === rowId);
      newData[rowIndex] = res.data.data;
      setData((d) => ({ ...d, data: newData }));
      sweetAlert("Done");
    }
    setRowId("");
    setSpinner(false);
  };

  const handleNavigate = (obj) => {
    history.push(`/${culture}-admin/userdetails/1/10/${obj.applicationUserId}`);
  };

  const editRenderer = () => checkAbility("edit");

  const images = watch("image") || {};

  const handleSearch = async () => {
    const res = await searchHandler(getByColumn, pageNo, docsPerPage);
    if (res.ok) {
      updateData(res.data.data);
      register({ key: 1, pageNo, docsPerPage, pageName });
    }
  };

  const renderActivateIcon = (obj) =>
    !obj.applicationUser?.isActive && checkAbility("useractive", "User");

  const renderDeactivateIcon = (obj) =>
    obj.applicationUser?.isActive && checkAbility("userdeactive", "User");

  useCheckRequest({
    pageNo,
    isRemove,
    setIsRemove,
    requests: [getCustomers, handleSearch],
    setPageNo,
    setDocsPerPage,
    docsPerPage,
    key,
    register,
    // depandancies: [location],
    resetAdvanceFilter,
  });

  if (customersApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {customersApi.errorAlert}
      {createCustomerApi.errorAlert}
      {deleteCustomerApi.errorAlert}
      {updateCustomerApi.errorAlert}
      {getByColumn.errorAlert}
      {deactivateUserApi.errorAlert}
      {activateUserApi.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[content.page[culture]]} backBtn />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.page[culture]}</h3>
            </div>
          </CardHeader>
          {checkAbility("create") && (
            <CardBody>
              <form onSubmit={handleSubmit(createCustomer)}>
                <Row>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        {commonContent.name[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="customerName"
                        rules={{
                          required: "name field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="name"
                            placeholder={commonContent.name[culture]}
                            type="text"
                            className={errors.customerName && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="phone">
                        {commonContent.phone[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="phone"
                        rules={{
                          pattern: {
                            value:
                              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                            message: "only english character allowed",
                          },
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="phone"
                            placeholder={commonContent.phone[culture]}
                            type="text"
                            className={errors.phone && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="priceType">
                        {commonContent.type[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="priceType"
                        rules={{ required: true }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="priceType"
                            type="select"
                            className={errors.priceType && "error"}
                            value={value || "disabled"}
                            onChange={({ target: { value } }) =>
                              onChange(+value)
                            }
                          >
                            <option disabled value="disabled">
                              -- {commonContent.selectAnOption[culture]} --
                            </option>
                            {env.customerType.map((type, i) => (
                              <option value={i + 1} key={i + 1}>
                                {type[culture]}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="userName">
                        {commonContent.userName[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="username"
                        rules={{
                          required: "username field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="userName"
                            placeholder={commonContent.userName[culture]}
                            type="text"
                            className={errors.username && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="password">
                        {commonContent.password[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="password"
                        rules={{
                          required: "username field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="password"
                            placeholder={commonContent.password[culture]}
                            type="text"
                            className={errors.password && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="userType">
                        {commonContent.userType[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="userType"
                        rules={{
                          required: "user type field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="userType"
                            placeholder={commonContent.userType[culture]}
                            type="select"
                            className={errors.userType && "error"}
                            value={value || "disabled"}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          >
                            <option disabled value="disabled">
                              -- {commonContent.selectAnOption[culture]} --
                            </option>
                            {env.usersType.map((type, index) => (
                              <option value={index + 1} key={index + 1}>
                                {commonContent[type]?.[culture]}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="currentBranchId"
                      >
                        {commonContent.branch[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="currentBranchId"
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="currentBranchId"
                            type="select"
                            className={errors.currentBranchId && "error"}
                            value={value || "disabled"}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          >
                            <option disabled value="disabled">
                              -- {commonContent.selectAnOption[culture]} --
                            </option>
                            {data.branches?.map((branch) => (
                              <option value={branch.id} key={branch.id}>
                                {branch.name}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FilePicker
                  images={images}
                  setImages={(img) => setValue("image", img)}
                />
                <Button
                  color="success"
                  type="submit"
                  disabled={!Object.keys(images).length}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>{commonContent.create[culture]}</span>
                </Button>
              </form>
            </CardBody>
          )}
        </Card>
        <Card>
          <Pagination
            dataLength={data.data?.length}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <AdvanceTable
              pageName={pageName}
              headers={headers}
              columns={columns}
              setHeaders={setHeaders}
              setColumns={setColumns}
              data={data.data}
              setData={(sortedData) =>
                setData((d) => ({ ...d, data: sortedData }))
              }
              handleEdit={handleEdit}
              handleNavigate={handleNavigate}
              editRenderer={editRenderer}
              // onHeaderClick={searchPopup.handleHeaderClicked}
              onHeaderClick={onHeaderClick}
              searchedHeaders={header}
              handleRemove={handleRemove}
              handleActivate={handleActivate}
              handleDeactivate={handleDeactivate}
              renderActivateIcon={renderActivateIcon}
              renderDeactivateIcon={renderDeactivateIcon}
            >
              <span
                fun="handleNavigate"
                // to={`/${culture}-admin/userdetails/${pageNo}/${docsPerPage}`}
              >
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-eye fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commonContent.showDetails[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                fun="handleEdit"
                className="me-1 ms-1"
                condition="editRenderer"
              >
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commonContent.edit[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                fun="handleActivate"
                className="me-1"
                condition="renderActivateIcon"
              >
                <div id="activate" className="table-action cursor-pointer">
                  <i className="fas fa-bolt fa-lg hover-primary" />
                </div>
                <UncontrolledTooltip delay={0} target="activate">
                  {commenContent.activate[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                fun="handleDeactivate"
                className="me-1"
                condition="renderDeactivateIcon"
              >
                <div id="deactivate" className="table-action cursor-pointer">
                  <i className="fas fa-ban fa-lg hover-primary" />
                </div>
                <UncontrolledTooltip delay={0} target="deactivate">
                  {commenContent.deactivate[culture]}
                </UncontrolledTooltip>
              </span>
            </AdvanceTable>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={editModal}
        text={commonContent.save[culture]}
        handleCancel={handleCancel}
        fn={editHandleSubmit(editCustomerHandler)}
        color="primary"
      >
        <Container>
          <EditCustomer
            control={editControl}
            errors={editErrors}
            branches={data?.branches || []}
          />
        </Container>
      </TextPopup>
      <TextPopup
        modal={deleteModal}
        text={commonContent.yes[culture]}
        handleCancel={handleCancel}
        fn={deleteRow}
        color="danger"
      >
        <Container>
          <h2>{content.deletePopup[culture]}</h2>
        </Container>
      </TextPopup>
      <TextPopup
        modal={activateModal}
        text="Yes"
        handleCancel={handleCancel}
        fn={activateUserHandler}
        color="primary"
        name={
          data.data?.find((item) => item.applicationUserId === rowId)
            ?.customerName
        }
      >
        <Container>
          <h3>{content.activateCustomer[culture]}</h3>
        </Container>
      </TextPopup>
      <TextPopup
        modal={deactivateModal}
        text="Yes"
        handleCancel={handleCancel}
        fn={deactivateUserHandler}
        color="primary"
        name={
          data.data?.find((item) => item.applicationUserId === rowId)
            ?.customerName
        }
      >
        <Container>
          <h3>{content.deactivateCustomer[culture]}</h3>
        </Container>
      </TextPopup>
      {openPopup && (
        <AdvanceSearchModal
          setKey={setKey}
          openPopup={openPopup}
          pageName={pageName}
          getByColumn={getByColumn}
          updateData={updateData}
          setParentPageNo={setPageNo}
          setParentDocsPerPage={setDocsPerPage}
          setParentSearchField={setSearchField}
          handleClose={closeAdvanceSearch}
          editResponse={editResponse}
          extraParams={[]}
          extraField={{
            header: commenContent.name[culture],
            column: "customerName",
          }}
        />
      )}
    </div>
  );
};

export default CustomerSettings;

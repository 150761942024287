import client from "./client";

const endpoint = "/v1/home";

const searchOrder = (number) =>
  client.get(`${endpoint}/Commodity?number=${number}`);

const buzznasOrder = (number) =>
  client.get(`${endpoint}/buzznasOrder?number=${number}`);

export default {
  searchOrder,
  buzznasOrder,
};

import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import content from "./components/content";
import SimpleHeader from "components/Headers/SimpleHeader";
import useCulture from "hooks/useCulture";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import useAbility from "hooks/useAbility";
import commenContent from "components/common/content";
import env from "env";
import FilePicker from "components/common/FilePicker";
import useFCL from "./useFCL";
import Spinner from "components/Spinner";
import PopupTable from "components/common/PopupTableUsed";
import TextPopup from "components/common/TextModal";
import CountryTable from "components/common/CountryTable";
import Tables from "components/common/CustomTable";
import { PaginationContext } from "context/PaginationContext";
import Pagination from "components/common/Pagination";
import SearchModal from "components/common/SearchModal";
import AdvanceTable from "components/common/AdvanceTable";
import AdvanceSearchModal from "components/common/AdvanceSearchModal";
import { bizznassCustomerCode } from "env";

const FCL = () => {
  const culture = useCulture();
  const checkAbility = useAbility("LCL");
  const [tableModal, setTableModal] = useState(false);
  const [countryTable, setCountryTable] = useState(false);

  // Use form hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch,
  } = useForm();

  const {
    data,
    setData,
    pageName,
    pageNo,
    image,
    alert,
    sweetAlert,
    spinner,
    headers,
    setImage,
    columns,
    setPageNo,
    updateData,
    setHeaders,
    setColumns,
    errorMessage,
    docsPerPage,
    searchPopup,
    handleCreate,
    editResponse,
    FCLPreloadApi,
    setDocsPerPage,
    handleRemove,
    header,
    onHeaderClick,
    setKey,
    openPopup,
    setOpenPopup,
    setSearchField,
    getByColumn,
    closeAdvanceSearch,
  } = useFCL(reset);

  const paginationCtx = useContext(PaginationContext);

  const handleCancel = () => {
    setTableModal(false);
    setCountryTable(false);
  };

  const customerCode = watch("customerCode");

  if (FCLPreloadApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {errorMessage}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[content.fcl[culture]]} />
      <Container className="mt--6" fluid>
        {checkAbility("create") && (
          <Card>
            <CardHeader>
              <h3 className="mb-0">{content.fcl[culture]}</h3>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(handleCreate)}>
                <Row>
                  <Col md="4" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="client">
                        {commenContent.customer[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="client"
                        rules={{
                          required: `${content.client[culture]} field is required`,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="client"
                            placeholder={commenContent.client[culture]}
                            type="text"
                            className={errors.client && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => setTableModal(true)}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="country">
                        {commenContent.country[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="country"
                        rules={{ required: "Country feild is required" }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="country"
                            type="text"
                            className={errors.country && "error"}
                            value={value || ""}
                            placeholder={`-- ${commenContent.selectAnOption[culture]} --`}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => setCountryTable(true)}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="branch">
                        {commenContent.branch[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="branchId"
                        rules={{ required: "branch feild is required" }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="branch"
                            type="select"
                            className={errors.branchId && "error"}
                            value={value || "disabled"}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          >
                            <option disabled value="disabled">
                              -- {commenContent.selectAnOption[culture]} --
                            </option>
                            {data.branch?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="billLoading"
                      >
                        {content.billLoading[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="billLoading"
                        rules={{ required: "bill Loading feild is required" }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="billLoading"
                            type="text"
                            className={errors.billLoading && "error"}
                            value={value || ""}
                            placeholder={content.billLoading[culture]}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="quantity">
                        {content.quantity[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="qte"
                        rules={{
                          required: "quantity feild is required",
                          pattern: {
                            value: /^[1-9](\d)*$/,
                          },
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="quantity"
                            type="number"
                            className={errors.qte && "error"}
                            value={value || ""}
                            placeholder={content.quantity[culture]}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="polNumber">
                        {content.polNumber[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="polNumber"
                        rules={{
                          required: "pol Number feild is required",
                          pattern: {
                            value: /^[1-9](\d)*$/,
                          },
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="quantity"
                            type="text"
                            className={errors.polNumber && "error"}
                            value={value || ""}
                            placeholder={content.polNumber[culture]}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="polDate">
                        {content.polDate[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="polDate"
                        rules={{
                          required: "polDate feild is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="polDate"
                            type="date"
                            className={errors.polDate && "error"}
                            value={value || ""}
                            placeholder={content.polDate[culture]}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="commidityType"
                      >
                        {commenContent.commodityType[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="commodityType"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="commidityType"
                            type="select"
                            value={value || "disabled"}
                            onChange={({ target: { value } }) =>
                              onChange(+value)
                            }
                          >
                            <option disabled value="disabled">
                              -- {commenContent.selectAnOption[culture]} --
                            </option>
                            {env.commidityType.map((status, i) => (
                              <option key={i} value={i + 1}>
                                {status}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="trackNumber"
                      >
                        {content.trackNumber[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="trackNumber"
                        rules={{
                          required: "Track number feild is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="trackNumber"
                            placeholder={content.trackNumber[culture]}
                            type="text"
                            className={errors.trackNumber && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="trackNumber"
                      >
                        {content.trackLine[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="trackLine"
                        rules={{
                          required: "Track line feild is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="trackLine"
                            placeholder={content.trackLine[culture]}
                            type="select"
                            className={errors.trackLine && "error"}
                            value={+value || "disabled"}
                            onChange={({ target: { value } }) =>
                              onChange(+value)
                            }
                          >
                            <option disabled value="disabled">
                              {`-- ${commenContent.selectOption[culture]} --`}
                            </option>
                            {env.trackLine.map((item, index) => (
                              <option key={item} value={index + 1}>
                                {item}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="size">
                        {commenContent.size[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="size"
                        rules={{ required: "Size feild is required" }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="size"
                            placeholder={commenContent.size[culture]}
                            type="select"
                            className={errors.size && "error"}
                            value={value || "disabled"}
                            onChange={({ target: { value } }) =>
                              onChange(+value)
                            }
                          >
                            <option disabled value="disabled">
                              {`-- ${commenContent.selectOption[culture]} --`}
                            </option>
                            {env.size.map((size, index) => (
                              <option key={size} value={index + 1}>
                                {size}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="price-value"
                      >
                        {content.containerPrice[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="containerPrice"
                        rules={{
                          required: "container Price value field is required",
                          pattern: {
                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          },
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="price-value"
                            placeholder={content.containerPrice[culture]}
                            type="number"
                            className={errors.containerPrice && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onWheel={(e) => e.target.blur()}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="price-value"
                      >
                        {content.containerSellPrice[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="containerSellPrice"
                        rules={{
                          required:
                            "container sell Price value field is required",
                          pattern: {
                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          },
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="price-value"
                            placeholder={content.containerSellPrice[culture]}
                            type="number"
                            className={errors.containerSellPrice && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onWheel={(e) => e.target.blur()}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  {customerCode === bizznassCustomerCode && (
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="buzznassOrderNumber"
                        >
                          {commenContent.buzznassOrderNumber[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="buzznassOrderNumber"
                          rules={{ required: true }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="buzznassOrderNumber"
                              type="text"
                              className={errors.buzznassOrderNumber && "error"}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                              onWheel={(e) => e.target.blur()}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="commodity">
                        {commenContent.commodity[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="commodity"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="commodity"
                            type="textarea"
                            style={{ resize: "none" }}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div>
                  <FilePicker images={image} setImages={setImage} />
                </div>
                <Button color="success" type="submit">
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>{commenContent.create[culture]}</span>
                </Button>
              </form>
            </CardBody>
          </Card>
        )}
        <Card>
          <Pagination
            dataLength={data.data?.length}
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <AdvanceTable
              pageName={pageName}
              data={data.data}
              headers={headers}
              columns={columns}
              setHeaders={setHeaders}
              setColumns={setColumns}
              setData={(sortedData) =>
                setData((d) => ({ ...d, data: sortedData }))
              }
              onHeaderClick={onHeaderClick}
              searchedHeaders={header}
              handleRemove={handleRemove}
            >
              <span to={`/${culture}-admin/fcl`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="far fa-eye fa-lg hover-success"></i>
                </div>
                <UncontrolledTooltip delay={0} target="details1">
                  {commenContent.showDetails[culture]}
                </UncontrolledTooltip>
              </span>
            </AdvanceTable>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={tableModal}
        text="Next"
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <PopupTable
            setCustomer={(customer) => {
              setValue("customerId", customer.id);
              setValue("customerCode", customer.customerCode);
              setValue("branchId", customer.currentBranchId);
            }}
            setTableModal={setTableModal}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
      <TextPopup
        modal={countryTable}
        text="Next"
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <CountryTable
            setCountryTable={setCountryTable}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
      {openPopup && (
        <AdvanceSearchModal
          setKey={setKey}
          openPopup={openPopup}
          pageName={pageName}
          getByColumn={getByColumn}
          updateData={updateData}
          setParentPageNo={setPageNo}
          setParentDocsPerPage={setDocsPerPage}
          setParentSearchField={setSearchField}
          handleClose={closeAdvanceSearch}
          editResponse={editResponse}
          extraParams={[]}
          extraField={{
            header: commenContent.orderNumber[culture],
            column: "orderNumber",
          }}
        />
      )}
    </div>
  );
};

export default FCL;

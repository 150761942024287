import client from "./client";

const endpoint = "/v1/User";

const users = (page, docsPerPage) =>
  client.get(`${endpoint}?Page=${page}&PostsPerPage=${docsPerPage}`);

const notActiveUsers = (page, docPerPage) =>
  client.get(
    `${endpoint}/UserNonActive?Page=${page}&PostsPerPage=${docPerPage}`
  );

const findById = (id) => client.get(`${endpoint}/UserById?id=${id}`);

const getById = (id) => client.get(`${endpoint}/GetById?id=${id}`);

const getByColumn = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const addRole = (schema) => client.post(`${endpoint}/addrole`, schema);

const updateUser = (schema, id) =>
  client.put(`${endpoint}/id?id=${id}`, schema);

const filter = (content, pageNo, docsPerPage) =>
  client.get(
    `${endpoint}/filter?id=${content}&page=${pageNo}&PostsPerPage=${docsPerPage}`
  );

const userActive = (id) => client.put(`${endpoint}/useractive/${id}`);

const dashboard = () => client.get(`${endpoint}/dashboard`);

const getNotApprovedByColumn = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/GetNotApprovedUsersByCoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const getNormalUsersByColumn = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/GetNormalUsersByCoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const deleteNotActiveUsers = (id) =>
  client.delete(`${endpoint}/notacitve?id=${id}`);

const needProccessing = () => client.get(`${endpoint}/NeedProccessing`);

const userDeactive = (id) => client.put(`${endpoint}/userDeactive/${id}`);

export default {
  users,
  notActiveUsers,
  findById,
  getByColumn,
  addRole,
  updateUser,
  getById,
  filter,
  userActive,
  dashboard,
  getNotApprovedByColumn,
  getNormalUsersByColumn,
  deleteNotActiveUsers,
  needProccessing,
  userDeactive
};

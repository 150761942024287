const usePackageFilter = (
  setData,
  shipmentType,
  fn,
  pageNo,
  docsPerPage,
  editResponse
) => {
  const filter = async (api, bulkId, search) => {
    if (!search && bulkId) return fn();
    const res = await api.request(
      shipmentType,
      bulkId,
      search,
      pageNo,
      docsPerPage
    );
    if (res.status === 200) {
      res.data.data.forEach(editResponse);
      setData(res.data.data);
    }
  };

  return filter;
};

export default usePackageFilter;

import { useState } from "react";
import FCLBK from "api/FCLBK";
import useApi from "hooks/useApi";
import useFormData from "hooks/useFormData";
import commenContent from "components/common/content";
import useCulture from "hooks/useCulture";

import env from "env";
import useAlert from "hooks/useAlert";
import useAdvanceSearch from "hooks/useAdvanceSearch";
import useCheckRequest from "hooks/useCheckRequest";

const useFCL = (reset) => {
  const culture = useCulture();

  const [data, setData] = useState({});
  const [image, setImage] = useState({});
  const [spinner, setSpinner] = useState(false);

  const { alert, sweetAlert } = useAlert();

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.orderNumber[culture],
    commenContent.customerCode[culture],
    commenContent.customer[culture],
    commenContent.followUpStatus[culture],
    commenContent.country[culture],
    commenContent.date[culture],
    commenContent.recieveDate[culture],
    commenContent.stateDate[culture],
    commenContent.commodity[culture],
    commenContent.commodityType[culture],
    commenContent.branch[culture],
    commenContent.rate[culture],
  ]);

  const [columns, setColumns] = useState([
    "orderNumber",
    "customer.customerCode",
    "customer.customerName",
    "followUpStatusText",
    "country.name",
    "createdOn",
    "recieveDate",
    "statusDate",
    "commodity",
    "commodityTypeText",
    "branch.name",
    "rate",
  ]);

  const FCLPreloadApi = useApi(FCLBK.FCLPreload);
  const createFCLApi = useApi(FCLBK.createFCL);
  // const getByColumn = useApi(FCLBK.getByColumn);
  const getByColumn = useApi(FCLBK.advanceFilter);

  const formDataGenerator = useFormData();

  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = "recivedFcl";
  // const { pageNo, setPageNo, docsPerPage, setDocsPerPage, prevPageCheck } =
  //   usePagination(pageName);

  const editResponse = (row) => {
    row.createdOn = row.createdOn?.split("T")[0];
    row.recieveDate = row.recieveDate?.split("T")[0];
    row.statusDate = row.statusDate?.split("T")[0];
    // Replace the normal status with the text from env variable
    const thisStatusIndex = env.followUpStatus.findIndex(
      (status) => status.key === row.followUpStatus
    );

    row.followUpStatusText = env.followUpStatus[thisStatusIndex]?.[culture];

    row.orderNumber = `${row.country?.countryCode}-${row.orderNumber}FC`;

    row.commodityTypeText = env.commodityType?.[row.commodityType - 1];

    return row;
  };

  const {
    key,
    setKey,
    openPopup,
    setOpenPopup,
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    register,
    onHeaderClick,
    searchHandler,
    setSearchField,
    header,
    isRemove,
    setIsRemove,
    handleRemove,
    resetAdvanceFilter,
  } = useAdvanceSearch(pageName, editResponse);

  const errorMessage =
    FCLPreloadApi.errorAlert ||
    createFCLApi.errorAlert ||
    getByColumn.errorAlert ||
    "";

  const updateData = (arr) => {
    setData((data) => ({
      ...data,
      data: arr,
    }));
  };

  const preload = async () => {
    const res = await FCLPreloadApi.request(pageNo, docsPerPage);
    if (res.status === 200) {
      res.data.data.data.forEach((row) => editResponse(row));
      setData(res.data.data);
    }
  };

  const handleCreate = async (formData) => {
    setSpinner(true);
    const schema = formDataGenerator({ ...formData, image });

    const res = await createFCLApi.request(schema);
    if (res.status === 200) {
      editResponse(res.data.data);
      setData((d) => ({
        ...d,
        data: [res.data.data, ...d.data],
      }));
      sweetAlert(commenContent.done[culture]);
      reset();
    }
    setSpinner(false);
  };

  const handleSearch = async () => {
    const res = await searchHandler(getByColumn, pageNo, docsPerPage);
    if (res.ok) {
      updateData(res.data.data);
      register({ key: 1, pageNo, docsPerPage, pageName });
    }
  };

  const closeAdvanceSearch = (index) => {
    handleRemove(index, false);
    setOpenPopup(false);
  };

  useCheckRequest({
    pageNo,
    isRemove,
    setIsRemove,
    requests: [preload, handleSearch],
    setPageNo,
    setDocsPerPage,
    docsPerPage,
    key,
    register,
    resetAdvanceFilter,
  });

  return {
    data,
    setData,
    pageName,
    pageNo,
    image,
    headers,
    alert,
    sweetAlert,
    setImage,
    columns,
    spinner,
    setPageNo,
    updateData,
    setHeaders,
    setColumns,
    errorMessage,
    docsPerPage,
    // searchPopup,
    handleCreate,
    createFCLApi,
    editResponse,
    FCLPreloadApi,
    setDocsPerPage,
    handleRemove,
    header,
    onHeaderClick,
    setKey,
    openPopup,
    setOpenPopup,
    setSearchField,
    getByColumn,
    closeAdvanceSearch,
  };
};

export default useFCL;

import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardHeader,
  CardBody,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import useApi from "../../../hooks/useApi";
import shipmentApi from "api/shipment";
import Spinner from "../../../components/Spinner";
import env from "env";
import { useForm, Controller } from "react-hook-form";
import TextPopup from "components/common/TextModal";
import Pagination from "components/common/Pagination";
import PopupTable from "components/common/PopupTableUsed";
import PopupOldTicketTable from "components/common/PopupOldTicketTable";
import useAlert from "hooks/useAlert";
import { PaginationContext } from "context/PaginationContext";
import usePagination from "hooks/usePagination";
import NavPills from "components/common/NavPills";
import TicketApi from "../../../api/TicketApi";
import useDelete from "hooks/useDelete";
import useTicketDelete from "hooks/useTicketDelete";
import content from "../content";
import commenContent from "components/common/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";
import UserPopup from "components/common/UserPopup";
import useAbility from "hooks/useAbility";
import useSearchPopup from "hooks/useSearchPopup";
import usePrevRequest from "hooks/usePrevRequest";
import SearchModal from "components/common/SearchModal";
import useFormData from "hooks/useFormData";
import FilePicker from "components/common/FilePicker";

const MyTickets = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const checkAbility = useAbility("Ticket");

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.subject[culture],
    commenContent.customer[culture],
    commenContent.customerCode[culture],
    commenContent.user[culture],
    content.tecketType[culture],
    commenContent.description[culture],
  ]);

  const [columns, setColumns] = useState([
    "subject",
    "customer.customerName",
    "customer.customerCode",
    "currentHolder.fullName",
    "ticketType",
    "description",
  ]);

  const [data, setData] = useState([]);
  const [navPills, setNavPills] = useState(0);
  const [tableModal, setTableModal] = useState(false);
  const [oldTicketTableModal, setOldTicketTableModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [rowId, setRowId] = useState("");
  const [reopenModal, setReopenModal] = useState(false);
  const [solvedModal, setSolvedModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [pendingModal, setPendingModal] = useState(false);
  const [assingModal, setAssingModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [images, setImages] = useState({});

  const ticketsApi = useApi(TicketApi.getTickets);
  const createTicket = useApi(TicketApi.createTicket);
  const updateState = useApi(TicketApi.updateState);
  const deleteTicketApi = useApi(TicketApi.deleteTicket);
  const assignHolderApi = useApi(TicketApi.assignHolder);
  const getByColumn = useApi(TicketApi.getMyTicketsByCoulum);

  const paginationCtx = useContext(PaginationContext);

  const formDataGenerator = useFormData();

  const editResponse = (row) => {
    row.ticketType = env.ticketType[row.ticketType - 1];
    return row;
  };

  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data.data,
    editResponse
  );

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  const solvedHook = useDelete(
    data,
    setData,
    updateState,
    setSpinner,
    setSolvedModal,
    sweetAlert
  );
  const reopenHook = useTicketDelete(
    updateState,
    setSpinner,
    setReopenModal,
    sweetAlert
  );
  const pendingHook = useDelete(
    data,
    setData,
    updateState,
    setSpinner,
    setPendingModal,
    sweetAlert
  );
  const closeHook = useDelete(
    data,
    setData,
    updateState,
    setSpinner,
    setCloseModal,
    sweetAlert
  );
  const deleteHook = useDelete(
    data,
    setData,
    deleteTicketApi,
    setSpinner,
    setDeleteModal,
    sweetAlert
  );
  const assignHolderHook = useDelete(
    data,
    setData,
    assignHolderApi,
    setSpinner,
    setAssingModal,
    sweetAlert
  );

  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = "myticket";
  const { pageNo, setPageNo, docsPerPage, setDocsPerPage, prevPageCheck } =
    usePagination(pageName);

  // Use form hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();

  const handleCancel = () => {
    setTableModal(false);
    setOldTicketTableModal(false);
    setReopenModal(false);
    setSolvedModal(false);
    setDeleteModal(false);
    setCloseModal(false);
    setAssingModal(false);
    setPendingModal(false);
    setRowId("");
  };

  const handleCreate = async (formData) => {
    setSpinner(true);

    const schema = formDataGenerator(formData);
    // const schema = {
    //   ...formData,
    //   // customerid: "a7a3a6a6-9afe-4676-866c-2d288f975689",
    // };

    const res = await createTicket.request(schema);

    if (res.ok) {
      // if the open tab is clicked
      // add the new row
      if (navPills === 0) {
        const newData = [res.data.data, ...data];
        editResponse(res.data.data);

        setData(newData);
      }
      reset();
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  const links = [
    content.opened[culture],
    content.resolved[culture],
    content.closed[culture],
    commenContent.pending[culture],
  ];

  const getTickets = async () => {
    const reqLinks = [
      "/user/open",
      "/user/resolved",
      "/user/closed",
      "/user/pending",
    ];

    const res = await ticketsApi.request(
      pageNo,
      docsPerPage,
      reqLinks[navPills]
    );

    if (res.status === 200) {
      res.data.data.forEach((item) => editResponse(item));
      setData(res.data.data);
    }
  };

  const renderReopen = (obj) => obj.ticketState === 3 && checkAbility("reOpen");

  const renderSolved = (obj) =>
    obj.ticketState === 4 && checkAbility("resolve");

  const renderDelete = (obj) => obj.ticketState === 1 && checkAbility("delete");

  const renderClose = (obj) => obj.ticketState === 4 && checkAbility("close");

  const renderPending = (obj) =>
    obj.ticketState === 1 && checkAbility("pending");

  const renderAssignHolder = (obj) =>
    obj.ticketState === 1 && checkAbility("assignHolder");

  const assignUserHandler = async (userId) => assignHolderHook(rowId, userId);

  const handleReopen = (obj) => {
    setRowId(obj.id);
    setReopenModal(true);
  };

  const handlePending = (obj) => {
    setRowId(obj.id);
    setPendingModal(true);
  };

  const handleSolved = (obj) => {
    setRowId(obj.id);
    setSolvedModal(true);
  };
  const handleDelete = (obj) => {
    setRowId(obj.id);
    setDeleteModal(true);
  };

  const handleClose = (obj) => {
    setRowId(obj.id);
    setCloseModal(true);
  };

  const handleAssign = (obj) => {
    setRowId(obj.id);
    setAssingModal(true);
  };

  const openTicket = async () => await reopenHook(rowId, "reopen");
  const solvedTicket = async () => await solvedHook(rowId, "resolve");
  const deleteTicket = async () => await deleteHook(rowId, "delete");
  const closeTicket = async () => await closeHook(rowId, "close");
  const pendingTicket = async () => await pendingHook(rowId, "pending");

  const extraFilterField = navPills + 1;

  useEffect(() => {
    getTickets();
  }, [pageNo, docsPerPage, navPills]);

  if (ticketsApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <>
      {alert}
      {ticketsApi.errorAlert}
      {createTicket.errorAlert}
      {updateState.errorAlert}
      {assignHolderApi.errorAlert}
      {getByColumn.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      <SimpleHeader parents={["Customer service"]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>My Tickets</h3>
            </div>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(handleCreate)}>
              <Row>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="client">
                      {commenContent.customer[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="client"
                      rules={{ required: "Client field is required" }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="client"
                          placeholder={commenContent.customer[culture]}
                          type="text"
                          className={errors.client && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          onClick={() => setTableModal(true)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="subject">
                      {commenContent.subject[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="subject"
                      rules={{ required: "Subject feild is required" }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          // {...field}
                          id="subject"
                          placeholder={commenContent.subject[culture]}
                          type="text"
                          className={errors.subject && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="old-ticket">
                      {content.oldticket[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="odticketSubject"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="old-ticket"
                          placeholder={content.oldticket[culture]}
                          type="text"
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          onClick={() => setOldTicketTableModal(true)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <FormGroup>
                  <Controller
                    control={control}
                    name="image"
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FilePicker
                        {...field}
                        images={value || {}}
                        setImages={(files) => setValue("image", files)}
                      />
                    )}
                  />
                </FormGroup>
                <Col xs="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="description">
                      {commenContent.description[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="description"
                      rules={{ required: "Description feild is required" }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="description"
                          placeholder={commenContent.description[culture]}
                          type="textarea"
                          className={errors.description && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          onWheel={(e) => e.target.blur()}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button color="success" type="submit">
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>{commenContent.create[culture]}</span>
              </Button>
            </form>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <NavPills
              links={links}
              navPills={navPills}
              setNavPills={setNavPills}
            />
          </CardBody>
          <Pagination
            dataLength={data.length}
            // dataLength={searchPopup.isSelected ? searchPopup.hookData?.length : data.data?.length }
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table
              pageName={"my-ticket"}
              headers={headers}
              columns={columns}
              setColumns={setColumns}
              setHeaders={setHeaders}
              // when the user search using the popup and select a row
              // after then only use the hookData states from searchPopup hook
              data={data}
              setData={setData}
              renderReopen={renderReopen}
              renderSolved={renderSolved}
              renderClose={renderClose}
              renderDelete={renderDelete}
              renderAssignHolder={renderAssignHolder}
              renderPending={renderPending}
              handleReopen={handleReopen}
              handleSolved={handleSolved}
              handleDelete={handleDelete}
              handleClose={handleClose}
              handleAssign={handleAssign}
              handlePending={handlePending}
              onHeaderClick={searchPopup.handleHeaderClicked}
              visitedRowId={paginationCtx.visitedRowId}
              // when the user was searching useing the popup and select a row
              // store the popup pagination in the context
              // otherwise store the normal pagination in the context
              setPagination={
                searchPopup.isSelected
                  ? (rowId) =>
                      paginationCtx.setPagination(
                        searchPopup.pageNo,
                        searchPopup.docsPerPage,
                        rowId,
                        pageName
                      )
                  : (rowId) =>
                      paginationCtx.setPagination(
                        pageNo,
                        docsPerPage,
                        rowId,
                        pageName
                      )
              }
            >
              <span to={`/${culture}-admin/ticket`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="far fa-eye fa-lg hover-primary"></i>
                </div>
                <UncontrolledTooltip delay={0} target="details1">
                  {commenContent.showDetails[culture]}
                </UncontrolledTooltip>
              </span>
              <span fun="handleDelete" condition="renderDelete">
                <div id="delete" className="table-action cursor-pointer">
                  <i className="fas fa-trash hover-danger fa-lg" />
                </div>
                <UncontrolledTooltip delay={0} target="delete">
                  {commenContent.delete[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                fun="handleAssign"
                condition="renderAssignHolder"
                className="ms-1"
              >
                <div id="assign-holder" className="table-action cursor-pointer">
                  <i className="fas fa-user fa-lg hover-primary" />
                </div>
                <UncontrolledTooltip delay={0} target="assign-holder">
                  {content.assignHolder[culture]}
                </UncontrolledTooltip>
              </span>
              <span fun="handleReopen" condition="renderReopen">
                <div id="open" className="table-action cursor-pointer">
                  <i className="fas fa-lg fa-envelope-open-text hover-info" />
                </div>
                <UncontrolledTooltip delay={0} target="open">
                  {content.reOpen[culture]}
                </UncontrolledTooltip>
              </span>
              <span fun="handleSolved" condition="renderSolved">
                <div id="solved" className="table-action cursor-pointer">
                  <i className="fas fa-check fa-lg hover-success" />
                </div>
                <UncontrolledTooltip delay={0} target="solved">
                  {content.solved[culture]}
                </UncontrolledTooltip>
              </span>
              <span fun="handleClose" condition="renderClose">
                <div id="close" className="table-action cursor-pointer">
                  <i className="fas fa-times fa-lg hover-warning" />
                </div>
                <UncontrolledTooltip delay={0} target="close">
                  {commenContent.close[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                fun="handlePending"
                condition="renderPending"
                className="ms-1"
              >
                <div id="pending" className="table-action cursor-pointer">
                  <i className="far fa-clock fa-lg hover-primary" />
                </div>
                <UncontrolledTooltip delay={0} target="pending">
                  {commenContent.pending[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={tableModal}
        text={commenContent.next[culture]}
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <PopupTable
            setCustomer={(obj) => setValue("customerId", obj.id)}
            setTableModal={setTableModal}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
      <TextPopup
        modal={oldTicketTableModal}
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <PopupOldTicketTable
            setTicket={(obj) => setValue("oldTicketId", obj.id)}
            setTableModal={setOldTicketTableModal}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
      <TextPopup
        modal={reopenModal}
        handleCancel={handleCancel}
        fn={openTicket}
        color="primary"
        name={data.find((item) => item.id === rowId)?.subject}
        text="Continue"
      >
        <Container>
          <h2>{content.reopenTicketPopup[culture]}</h2>
        </Container>
      </TextPopup>
      <TextPopup
        modal={solvedModal}
        handleCancel={handleCancel}
        fn={solvedTicket}
        color="success"
        name={data.find((item) => item.id === rowId)?.subject}
        text="Continue"
      >
        <Container>
          <h2>{content.solveTicketPopup[culture]}</h2>
        </Container>
      </TextPopup>
      <TextPopup
        modal={deleteModal}
        handleCancel={handleCancel}
        fn={deleteTicket}
        color="danger"
        name={data.find((item) => item.id === rowId)?.subject}
        text="Continue"
      >
        <Container>
          <h2>{content.deleteTicketPopup[culture]}</h2>
        </Container>
      </TextPopup>
      <TextPopup
        modal={closeModal}
        handleCancel={handleCancel}
        fn={closeTicket}
        color="warning"
        name={data.find((item) => item.id === rowId)?.subject}
        text="Continue"
      >
        <Container>
          <h2>{content.closeTicketPopup[culture]}</h2>
        </Container>
      </TextPopup>
      <TextPopup
        modal={pendingModal}
        handleCancel={handleCancel}
        fn={pendingTicket}
        color="primary"
        name={data.find((item) => item.id === rowId)?.subject}
        text="Continue"
      >
        <Container>
          <h2>{content.pendingTicketPopup[culture]}</h2>
        </Container>
      </TextPopup>
      <TextPopup
        modal={assingModal}
        handleCancel={handleCancel}
        color="primary"
        name={data.find((item) => item.id === rowId)?.subject}
        text="Continue"
      >
        <UserPopup
          fn={assignUserHandler}
          setTableModal={setAssingModal}
          userId={userId}
          setUserId={setUserId}
          sweetAlert={sweetAlert}
        />
      </TextPopup>
      {searchPopup.openPopup && (
        <SearchModal
          data={data}
          setData={setData}
          updateData={setData}
          searchPopup={searchPopup}
          editResponse={editResponse}
          popupSearchCtx={paginationCtx.popupSearch}
          extraField={{
            header: content.subject[culture],
            column: "subject",
          }}
          extraFilterField={extraFilterField}
        />
      )}
    </>
  );
};

export default MyTickets;

import user from "api/user";
import useApi from "hooks/useApi";
import { createContext, useState, useContext, useEffect } from "react";

const INTERVALS_REQUEST_DURATION = 60000;

const UserManagementNotificationContext = createContext({
  haveNoneActiveUsers: 0,
});

export const UserManagementNotificationProvider = ({ children }) => {
  const [haveNoneActiveUsers, setHaveNoneActiveUsers] = useState(0);

  const needProccessingApi = useApi(user.needProccessing);

  const fetchProccessingUsers = async () => {
    const res = await needProccessingApi.request();
    if (res.status === 200) {
      setHaveNoneActiveUsers(res.data.data.noneActiveUsersCount);
    }
  };

  useEffect(() => {
    fetchProccessingUsers();
    let interval = setInterval(() => {
      fetchProccessingUsers();
    }, INTERVALS_REQUEST_DURATION);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <UserManagementNotificationContext.Provider value={{ haveNoneActiveUsers }}>
      {children}
    </UserManagementNotificationContext.Provider>
  );
};

export const UserManagementNotificationIcon = () => {
  const { haveNoneActiveUsers } = useContext(UserManagementNotificationContext);

  return haveNoneActiveUsers > 0 ? (
    <div
      style={{
        marginInlineStart: "auto",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        backgroundColor: "red",
        color: "white",
        display: "grid",
        placeItems: "center",
        fontSize: "11px",
      }}
    >
      {haveNoneActiveUsers > 99 ? "+99" : haveNoneActiveUsers}
    </div>
  ) : (
    <></>
  );
};

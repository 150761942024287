import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Container,
  UncontrolledTooltip,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { useForm } from "react-hook-form";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import Pagination from "components/common/Pagination";
import useSearchPopup from "hooks/useSearchPopup";
import env from "env";
import followUpStatus from "api/followUpStatus";
import useApi from "hooks/useApi";
import useAlert from "hooks/useAlert";
import SearchModal from "components/common/SearchModal";
import Spinner from "../../../components/Spinner";
import { PaginationContext } from "context/PaginationContext";
import usePagination from "hooks/usePagination";
import content from "./components/content";
import commenContent from "components/common/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";
import FCLBK from "api/FCLBK";

const FollowUpStatusFCL = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  // useParams
  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.orderNumber[culture],
    commenContent.customerCode[culture],
    commenContent.customerName[culture],
    commenContent.followUpStatus[culture],
    commenContent.country[culture],
    commenContent.date[culture],
    commenContent.receiveDate[culture],
    commenContent.stateDate[culture],
    commenContent.commodity[culture],
    commenContent.commodityType[culture],
  ]);

  const [columns, setColumns] = useState([
    "orderNumber",
    "customer.customerCode",
    "customer.customerName",
    "followUpStatusText",
    "country.name",
    "createdOn",
    "recieveDate",
    "statusDate",
    "commodity",
    "commodityTypeText",
  ]);

  // Apis
  const getFUS = useApi(followUpStatus.getFUS);
  const getByColumn = useApi(followUpStatus.getByColumn);
  const uploadImg = useApi(FCLBK.uploadImgFCL);

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();
  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = `fcl-followUpStatus`;
  const {
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    prevPageCheck,
    search,
    setSearch,
  } = usePagination(pageName);

  const paginationCtx = useContext(PaginationContext);

  // sttates
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editImage, setEditImage] = useState({});

  const {
    handleSubmit: editHandleSubmit,
    formState: editFormState,
    control: editControl,
    setValue: editSetValue,
  } = useForm();

  const editResponse = (res) => {
    res.createdOn = res.createdOn.split("T")[0];
    res.recieveDate = res.recieveDate?.split("T")[0];
    res.statusDate = res.statusDate?.split("T")[0];

    // Replace the normal status with the text from env variable
    const thisStatusIndex = env.followUpStatus.findIndex(
      (status) => status.key === res.followUpStatus
    );

    res.followUpStatusText = env.followUpStatus[thisStatusIndex]?.[culture];

    res.commodityTypeText = env.commodityType?.[res.commodityType - 1];

    res.orderNumber = `${res.country?.countryCode}-${res.orderNumber}FC`;

    return res;
  };

  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data,
    editResponse
  );

  const getData = async () => {
    const res = await getFUS.request("fcl", pageNo, docsPerPage);

    if (res.status === 200) {
      res.data.data = res.data.data.map((item) => editResponse(item));
      setData(res.data.data);

      // if the prev page is not the same as this one clear the context
      if (!prevPageCheck()) paginationCtx.resetPagination();
    }
  };

  const updateData = (arr) => {
    // const editedResponse = arr.map((response) => editResponse(response));

    setData(arr);
  };

  const handleSearch = async () => {
    // if search filed is empty get the normal request
    if (!search) return getData();

    paginationCtx.setSearch(search);
    // set the 'searchPopup' state to false to uses the search field data
    searchPopup.setIsSelected(false);

    const res = await getByColumn.request(
      "followUpStatus",
      search,
      pageNo,
      docsPerPage,
      "fcl"
    );

    if (res.status === 200) {
      const editedResponse = res.data.data.map((response) =>
        editResponse(response)
      );
      setData(editedResponse);
    }
  };

  const handleUpload = async (id) => {
    const res = await uploadImg.request(id, editImage);
    return res?.ok || false;
  };

  useEffect(() => {
    // if search popup has a values and it's the same page stored in the context
    // don't run these request
    // and go to the other useEffect
    if (searchPopup.isSelected && searchPopup.searchField && prevPageCheck())
      return;
    if (
      paginationCtx.searchedColumn &&
      paginationCtx.searchedContent &&
      prevPageCheck()
    )
      return;
    if (search) return handleSearch();

    // else
    getData();
  }, [pageNo, docsPerPage]);

  useEffect(() => {
    if (
      searchPopup.isSelected &&
      searchPopup.searchField &&
      searchPopup.openPopup
    ) {
      searchPopup.onSearch("fcl", () => {});
    } else if (searchPopup.isSelected && searchPopup.searchField) {
      searchPopup.onSearch("fcl", (data) => {
        updateData(data);
      });
    } else if (
      paginationCtx.searchedColumn &&
      paginationCtx.searchedContent &&
      prevPageCheck()
    ) {
      // if there's a popup search info in the context set it in the popup search
      // then set 'setIsSelected' to true so the search popup request will fire
      searchPopup.setColumn(paginationCtx.searchedColumn);
      searchPopup.setSearchField(paginationCtx.searchedContent);
      searchPopup.setPageNo(paginationCtx.pageNumber);
      searchPopup.setDocsPerPage(paginationCtx.docsPerPage);
      searchPopup.setIsSelected(true);
    } else {
      searchPopup.setIsSelected(false);
    }
  }, [searchPopup.pageNo, searchPopup.docsPerPage, searchPopup.isSelected]);

  return (
    <div>
      {alert}
      {getFUS.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getFUS.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader parents={[content.fcl[culture]]} />
          <Container className="mt--6" fluid>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <h3 className={`mb-0 text-md-left`}>
                    {content.followUpStatus[culture]}
                  </h3>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="follow-up-status"
                      >
                        {content.followUpStatus[culture]}
                      </label>
                      <Input
                        id="follow-up-status"
                        type="select"
                        value={search || "disabled"}
                        onChange={(e) => setSearch(e.target.value)}
                      >
                        <option disabled value="disabled">
                          {`-- ${commenContent.selectOption[culture]} --`}
                        </option>
                        {env.followUpStatus.map((status) => (
                          <option value={status.key} key={status.key}>
                            {status?.[culture]}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="mt-2">
                    <Button
                      color="success"
                      type="button"
                      className="mt-4"
                      onClick={handleSearch}
                    >
                      <span className="btn-inner--icon me-1">
                        <i className="fas fa-search" />
                      </span>
                      <span>{commenContent.search[culture]}</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
              <Pagination
                dataLength={data.length}
                // when the user search using the popup and select a row
                // after then only use the pagination states from searchPopup hook
                pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo}
                setPageNo={
                  searchPopup.isSelected ? searchPopup.setPageNo : setPageNo
                }
                docsPerPage={
                  searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage
                }
                setDocsPerPage={
                  searchPopup.isSelected
                    ? searchPopup.setDocsPerPage
                    : setDocsPerPage
                }
              >
                <Table
                  pageName={pageName}
                  headers={headers}
                  columns={columns}
                  setHeaders={setHeaders}
                  setColumns={setColumns}
                  // when the user search using the popup and select a row
                  // after then only use the hookData states from searchPopup hook
                  data={data}
                  setData={setData}
                  onHeaderClick={searchPopup.handleHeaderClicked}
                  visitedRowId={paginationCtx.visitedRowId}
                  // when the user was searching useing the popup and select a row
                  // store the popup pagination in the context
                  // otherwise store the normal pagination in the context
                  setPagination={
                    searchPopup.isSelected
                      ? (rowId) =>
                          paginationCtx.setPagination(
                            searchPopup.pageNo,
                            searchPopup.docsPerPage,
                            rowId,
                            pageName
                          )
                      : (rowId) =>
                          paginationCtx.setPagination(
                            pageNo,
                            docsPerPage,
                            rowId,
                            pageName
                          )
                  }
                >
                  <span to={`/${culture}-admin/fcl`}>
                    <div id="details1" className="table-action cursor-pointer">
                      <i className="far fa-eye fa-lg hover-success"></i>
                    </div>
                    <UncontrolledTooltip delay={0} target="details1">
                      {commenContent.showDetails[culture]}
                    </UncontrolledTooltip>
                  </span>
                </Table>
              </Pagination>
              {searchPopup.openPopup && (
                <SearchModal
                  data={data}
                  setData={setData}
                  updateData={updateData}
                  searchPopup={searchPopup}
                  editResponse={editResponse}
                  popupSearchCtx={paginationCtx.popupSearch}
                  shipmentType="fcl"
                  page=""
                />
              )}
            </Card>
          </Container>
        </>
      )}
    </div>
  );
};

export default FollowUpStatusFCL;

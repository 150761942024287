import { useState } from "react";
/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import {
  //BrowserRouter as Router,
  useRouteMatch,
} from "react-router-dom";
import getCulture from "utils/getCulture";
import shahen from "../../assets/img/brand/shahen.png";
function TimelineHeader({ parents, parentIds, api, backBtn }) {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);
  const history = useHistory();

  const handleBack = () => {
    if (typeof backBtn === "string") return history.replace(backBtn);
    return history.goBack();
  };

  return (
    <>
      <div className="header header-dark bg-success pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <div
              className="py-4"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {backBtn ? (
                <div>
                  <div className="d-flex justify-content-start">
                    <button className="back-btn" onClick={handleBack}>
                      <i className="fas fa-long-arrow-alt-left"></i>
                    </button>
                  </div>
                </div>
              ) : null}
              <div style={{ marginInline: "auto" }}>
                <img
                  src={shahen}
                  alt="shahen"
                  style={{ width: 160, objectFit: "contain" }}
                />
              </div>
              {/* <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  <Link 
                    to={parentIds ? parentIds[parentIds.length - 3] || parentIds[parentIds.length - 1] : ''}
                    className="nothing"
                    onClick={e => e.preventDefault()}  
                  >
                    {parents ? parents[parents.length - 3] || parents[parents.length - 1] : ''}
                  </Link>
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <Link 
                      to={api ? `../${api}-files` : `dashboard`} 
                    >
                      <i className="fas fa-home" />
                    </Link>
                  </BreadcrumbItem>
                
                  <BreadcrumbItem>
                    <Link to={parentIds ? parentIds[parentIds.length - 2] || '' : ''}>
                      {parents ? parents[parents.length - 2] || '' : ''}
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    <Link to={parentIds ? parentIds.length >= 3 ? parentIds[parentIds.length - 3] : '' : ''}>
                      {parents ? parents.length >= 3 ? parents[parents.length - 3] : '' : ''}
                    </Link>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col> */}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
